@import _/var

.banner-slick
    overflow: hidden


.banner-big
    width: 1200px
    height: 360px !important
    overflow: hidden
    *zoom: 1
    *z-index: -2
    .slick-prev,.slick-next
        position: absolute
        top: 138px
        width: 40px
        height: 120px
        font-size: 0px
        border: 0px
        outline: 0
    .slick-prev
        left: 0px
        +bgp(big_ban_arrow_left)
    .slick-next
        right: 0px
        +bgp(big_ban_arrow_right)
    .banner-img
        background-repeat: no-repeat !important
        background-position: center center
        background-size: cover
        height: 360px !important
        position: relative
    .slick-dots
        text-align: center
        position: absolute
        width: 100%
        bottom: 21px 
        font-size: 0px
        z-index: 15
        button
            width: 12px
            height: 12px
            border: 0
            cursor: pointer
            line-height: 0
            padding: 0px
            outline: none
            +bgp(big_ban_yuan,center)

        li
            display: inline-block
            *display: inline
            *zoom: 1
            margin: 0px 9px
            width: 12px
            height: 12px
            zoom: 1
    .slick-active 
        button
            +bgp(big_ban_yuanon,center)


.banner
    width: 390px
    height: 290px !important
    overflow: hidden
    *zoom: 1
    *z-index: 10
    .banner-img
        background-repeat: no-repeat !important
        background-position: center center
        background-size: cover
        height: 290px !important
        position: relative
    .banner-txt
        position: absolute
        width: 100%
        bottom: 0px
        left: 0px
        z-index: 10
        height: 40px
        color: #fff
        padding-left: 15px
        line-height: 40px
        font-size: 12px
        background-color: rgba(0,0,0,0.5)
        p 
            +tp(185px)
    .slick-dots
        text-align: center
        position: absolute
        bottom: 18px 
        font-size: 0px
        right: 15px
        z-index: 15

        button
            width: 18px
            height: 5px
            border: 0
            cursor: pointer
            line-height: 0
            padding: 0px
            outline: none
            background-color: #a5a5a5

        li
            display: inline-block
            *display: inline
            *zoom: 1
            margin-left: 5px
            width: 18px
            height: 5px
            zoom: 1
    .slick-active 
        button
            background-color: $main
.Hang
    overflow: hidden
    *zoom: 1
.box
    overflow: hidden
    *zoom: 1

    &.news
        width: 782px
        .box-con
            height: 312px
        .box-list
            width: 369px
    &.notice
        width: 375px
        .box-con
            height: 312px

    &.jiaowu,&.xuesu,&.xueke,&.dang,&.tuanxue,&.lilun
        width: 369px
        .box-con
            height: 350px
    &.xuesu,&.tuanxue
        margin-left: 44px

.box-ti
    height: 41px
    line-height: 32px
    margin-top: 22px
    border-bottom: 1px dotted rgb( 102, 102, 102 )
    a 
        // font-size: 12px 
        // color: #999999
        width: 49px
        height: 16px
        +bgp(more)
        margin-top: 11px
    h3

        color: #333
        padding-left: 40px
        font-size: 18px
        font-weight: bold
        &.news-ti 
            padding-left: 32px
            +bgp(icon1,left center)
        &.notice-ti 
            +bgp(icon2,left center)
        &.jiaowu-ti 
            padding-left: 32px
            +bgp(icon1,left center)
        &.xuesu-ti 
            +bgp(icon3,left center)
        &.xueke-ti 
            padding-left: 35px
            +bgp(icon4,left center)


        &.dang-ti 
            padding-left: 32px
            +bgp(icon5,left center)
        &.tuanxue-ti 
            +bgp(icon6,left center)
        &.lilun-ti 
            padding-left: 35px
            +bgp(icon7,left center)
        &.school-ti 
            +bgp(icon8,left center)
.box-con
    padding-top: 22px
    overflow: hidden
    *zoom: 1
.box-list
    li
        line-height: 2
        margin-bottom: 10px
        &:hover
            color: $main
            a
                color: $main

        a
            +tp(70%)
            font-size: 14px 
            color: #333
        .dotted
            width: 0px
            height: 0px
            border: 6px solid transparent
            border-left-color: $main
            margin-right: 3px
            margin-top: 8px
        .time
            font-size: 14px 
            color: #999



.box-school
    .slick-list
        margin-left: -20px
.school-item
    
    margin-left: 20px
    &:hover
        img
            transform: scale(1.1)
        p
            color: $main
    .school-item-img
        overflow: hidden
        width: 100%
        height: 170px
        border: 1px solid #e7e7e7
        +bs(0px 3px 0px 1px #ebebeb)
        img 
            width: 100%
            height: 100%
            -webkit-transition: all .5s
            -ms-transition: all .5s
            -moz-transition: all .5s
            transition: all .5s
    p
        font-size: 14px 
        +tp(100%)
        color: #333333
        line-height: 50px
        text-align: center

.link
    height: 70px
    background-color: #f2f6f9
    padding-left: 26px
    padding-right: 30px
    margin-bottom: 42px
    .linkfl
        width: 101px
        height: 30px
        +bgp(linkti,left center)
        margin-top: 20px
    .linkfr
        select
            
            margin-top: 19px
            font-size: 12px
            color: #999 
            width: 190px
            height: 32px
            *line-height: 32px
            border: 1px solid #dddddd
            padding-left: 10px
            margin-left: 15px
            +bgp(arrow_bto,160px center)
            *background: #fff
            background-color: #fff
            *margin-left: 10px
    

.classfiy
    margin-top: 30px
    li 
        width: 188px
        height: 80px
        margin-left: 14px
        position: relative
        &:hover
            &:before
                width: 80px
            &:after
                height: 40px

        &:after,&:before
            content: ""
            position: absolute
            background-color: #fff
            -webkit-transition: all .4s
            -ms-transition: all .4s
            -moz-transition: all .4s
            transition: all .4s

        &:before
            width: 0px
            height: 1px
            left: 0
            top: 5px
        &:after
            width: 1px
            height: 0px
            left: 5px
            top: 0px

        &.classfiyitem1
            margin-left: 0
        a 
            display: block
