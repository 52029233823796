

$red    : #d90000
$green  : #2F961C
$yellow : #ff0
$orange : #f60
$pink   : #f0f
$blue   : #108bc7
$txt    : #555
$link   : #08c
$line   : #e7e7e7
$main: #b11f1f
$bc: #e7e7e7
$yh     : "microsoft YaHei"
$st     : "SimSun"
$xst    : "NSimSun"
$ht     : "SimHei"
$kt     : "KaiTi"

// footer固定在底部，需要底部的高度
$footer: 120px

@import mixin
